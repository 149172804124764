import React, { Component } from "react";

import Fade from 'react-reveal/Fade';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
    this.lol = this.lol.bind(this);
  }
  lol() {
    this.setState({ show: !this.state.show });
  }
  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project;
    const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;

    
    

    return (
      <header id="home">
        

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade when={this.state.show} collapse>
              <div className = "cigbox">
                <img src="images\sig.gif" alt="hi" className = "sig" />
              </div>
            </Fade>
            <Fade bottom delay={7000} wait={7000} onReveal={ () => this.lol()  }>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom delay={7000} duration={1201} >
              <h3>{description}</h3>
            </Fade>
            <hr />
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
